import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {DatePicker} from 'antd';

function getEndDate({start_date, variant}) {
  const choice = variant.choice.split('個月月費')[0];
  const month = isNaN(choice) ? 1 : Number(choice) - 1;

  const startYear = new Date(start_date).getFullYear();
  const startMonth = new Date(start_date).getMonth();
  const startDate = new Date(start_date).getDate();

  const monthTable = [];

  monthTable.push({
    year: startYear,
    month: startMonth + 1,
    date: new Date(startYear, startMonth + 1, 0).getDate() - startDate,
  });

  for (let i = 0; i < month + 1; i++) {
    if (startMonth + 1 < 12) {
      const year = startYear;
      const month = startMonth + 1 + i + 1;
      const date = new Date(year, month, 0).getDate();

      monthTable.push({year, month, date});
    } else if (startMonth + 1 === 12) {
      const year = startYear + 1;
      const month = 1 + i;
      const date = new Date(year, month, 0).getDate();

      monthTable.push({year, month, date});
    }
  }

  if (monthTable.length > 1) {
    monthTable[monthTable.length - 1].date = startDate - 1;
  }

  const days = monthTable.reduce((acc, cur) => acc + cur.date, 0);

  const endDateTimeStamp =
    new Date(start_date).getTime() + days * 24 * 60 * 60 * 1000;

  return new Date(endDateTimeStamp).toLocaleDateString('sv');
}

function ProductCrossFeeFields(props) {
  const {product, currItemConfig, values, setValues} = props;

  React.useEffect(() => {
    if (
      currItemConfig &&
      Array.isArray(currItemConfig.variants) &&
      currItemConfig.variants.length > 0
    ) {
      if (!values.start_date || !values.end_date) {
        setValues({
          start_date: new Date().toLocaleDateString('sv'),
          end_date: getEndDate({
            start_date: new Date().toLocaleDateString('sv'),
            variant: currItemConfig.variants[0],
          }),
        });
        return;
      }

      const endDate = getEndDate({
        start_date: values.start_date,
        variant: currItemConfig.variants[0],
      });

      if (values.end_date && values.end_date !== endDate) {
        setValues({
          start_date: values.start_date,
          end_date: endDate,
        });
        return;
      }
    }
  }, [currItemConfig, setValues, values]);

  return (
    <Wrapper>
      <div className="name">執業日期</div>
      <DatePicker
        value={moment(values.start_date)}
        placeholder="開始日期"
        onChange={(date, dateString) => {
          setValues({
            start_date: dateString,
            end_date: getEndDate({
              start_date: dateString,
              variant: currItemConfig.variants[0],
            }),
          });
        }}
      />
      <span className="separate">至</span>
      <span>{values.end_date ? values.end_date : '---'}</span>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 10px;

  & > .name {
    margin-bottom: 9px;
    font-weight: 500;
  }

  & > .separate {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export default ProductCrossFeeFields;
