import React from 'react';
import styled from 'styled-components';
import {Descriptions, Switch, Button, message} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import AdminLineDivider from '../../Components/AdminLineDivider';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductWidget';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';
import ProductCrossFeeFields from '../../Components/ProductCrossFeeFields';

const product = '6401664a2512ae8cbb2b1af0'; //跨區會費;

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['user_id', 'product'],
    properties: {
      user_id: {
        type: 'string',
        title: '會員',
      },
      product: {
        type: 'string',
        title: '欲購買月費',
        readOnly: true,
      },
    },
  },
  uiSchema: {
    user_id: {
      'ui:widget': 'admin-select-user-custom-widget',
    },
    product: {
      'ui:widget': 'admin-select-product-widget',
    },
  },
};

export default function AdminUserCheckoutCross(props) {
  const [isFetching, setIsFetching] = React.useState(false);
  const [formSpec, setFormSpec] = React.useState(FormSpec);
  const [instance, setInstance] = React.useState(null);

  const [cart, setCart] = React.useState(null);
  const [extraConfigs, setExtraConfigs] = React.useState({});
  const [redirectUrl, setRedirectUrl] = React.useState(null);

  const fetchProductById = React.useCallback(async (id) => {
    try {
      setIsFetching(true);

      const targetProduct = await JStorage.fetchOneDocument('product', {
        _id: {$oid: product},
      });
      const hasVariants =
        Array.isArray(targetProduct.variants) &&
        targetProduct.variants.length > 0;

      if (hasVariants) {
        const nextFormSpec = {
          ...FormSpec,
          schema: {
            title: '',
            type: 'object',
            required: ['user_id', 'product'],
            properties: {
              user_id: {
                type: 'string',
                title: '會員',
              },
              product: {
                type: 'string',
                title: '欲購買月費',
                readOnly: true,
              },
            },
          },
        };

        const nextInstance = {
          user_id: '',
          product,
        };

        targetProduct.variants.map((e, index) => {
          nextFormSpec.schema.properties[e.name] = {
            type: 'string',
            title: `加價選項 ${e.name}`,
            default: e.choices.map((c) => c.name)[0],
            enum: e.choices.map((c) => c.name),
            enumNames: e.choices.map((c, i) => {
              return `[${c.label}] 加價費用：$${c.price}元`;
            }),
          };

          nextInstance[e.name] = e.choices.map((c) => c.name)[0];

          return {...e};
        });

        setFormSpec(nextFormSpec);
        setInstance(nextInstance);
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setIsFetching(false);
    }
  }, []);

  React.useEffect(() => {
    fetchProductById(product);
  }, [fetchProductById]);

  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <div className="title-container">
        <h1 className="title">跨區會費：快捷報名</h1>
      </div>
      {!isFetching && (
        <div className="content-container">
          <AdminLineDivider title="第一步：設定會員跨區會費" />
          <div>
            <GenericForm
              {...formSpec}
              instance={instance}
              rjsfProps={rjsfProps}
              renderCustomSection={(props) => {
                if (props.position === 'bottom') {
                  const {product, user_id, ..._variants} = props.values;

                  const names = Object.keys(_variants);
                  const choices = Object.values(_variants);

                  return (
                    <ProductCrossFeeFields
                      product={product}
                      currItemConfig={{
                        variants: names.map((n, i) => ({
                          name: n,
                          choice: choices[i],
                        })),
                      }}
                      values={extraConfigs}
                      setValues={setExtraConfigs}
                    />
                  );
                }
              }}
              onSubmit={async (formData) => {
                const {user_id, product, ...variants} = formData;

                if (!user_id) {
                  message.warning('請選擇會員');
                  AppActions.setLoading(false);
                  return;
                }

                try {
                  AppActions.setLoading(true);

                  //fetch cart
                  await AppActionsEx.adminFetchCartByUser({
                    user_id: formData.user_id,
                    initial_clear: true,
                    initial_raise: true,
                  });

                  //add to cart
                  const cart = await AppActionsEx.adminAddToCartByUser({
                    product,
                    user_id,
                    config: {
                      qty: 1,
                      variants: Object.keys(variants).map((k) => ({
                        name: k,
                        choice: variants[k],
                      })),
                      extra_data: extraConfigs,
                    },
                  });

                  setCart(cart);
                } catch (err) {
                  console.warn(err);

                  if (err.response) {
                    message.error(`發生錯誤：${err.response.error}`);
                  } else {
                    message.error('發生錯誤');
                  }
                } finally {
                  AppActions.setLoading(false);
                }
              }}
            />
          </div>

          <AdminLineDivider title="第二步：確認結帳金額" />
          <div>
            {cart && (
              <div>
                {(cart.discount_items.length > 0 ||
                  cart.extra_items.length > 0) && (
                  <Descriptions
                    bordered
                    title="價格計算"
                    style={{marginBottom: 20}}>
                    {cart.discount_items.map((item) => {
                      return (
                        <Descriptions.Item label={item.name} span={3}>
                          - ${item.amount}
                        </Descriptions.Item>
                      );
                    })}
                    {cart.extra_items.map((item) => {
                      return (
                        <Descriptions.Item label={item.name} span={3}>
                          + ${item.amount}
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                )}

                <div className="custom-field">
                  <div className="title">
                    付款方式：
                    {Cart.PAYMENT_SUBTYPE_DISPLAY[cart.payment_subtype]?.label}
                  </div>
                </div>
                <div className="custom-field">
                  <div className="total">總金額：${cart.total}</div>
                </div>
                <div className="actions">
                  <Button
                    style={{marginRight: 10}}
                    onClick={async () => {
                      await fetchProductById(product);
                      setCart(null);
                      setRedirectUrl(null);
                    }}>
                    清空報名資料
                  </Button>

                  <Button
                    type="primary"
                    onClick={async () => {
                      AppActions.setLoading(true);
                      try {
                        const resp = await AppActionsEx.adminCheckoutbyUser({
                          user_id: cart.owner,
                          use_full_points: false,
                        });

                        message.success('報名成功');
                        setRedirectUrl(resp.url);
                      } catch (err) {
                        console.warn(err);
                        message.error('發生錯誤');
                      } finally {
                        AppActions.setLoading(false);
                      }
                    }}>
                    確認結帳
                  </Button>
                  {redirectUrl && (
                    <Button
                      type="link"
                      target="_blank"
                      href={`${redirectUrl}&backPath=/admin/orders/export`}>
                      查看訂單
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 20px;

  & > .title-container {
    & > .title {
      margin-bottom: 10px;
      font-size: 32px;
    }
  }

  & > .content-container {
    margin-bottom: 10px;

    & .info {
      margin-bottom: 20px;
    }

    & .custom-field {
      margin-bottom: 20px;
      display: flex;

      & > .title {
        margin-right: 20px;
        font-size: 16px;
        font-weight: bold;
      }

      & > .total {
        font-size: 24px;
        font-weight: bold;
        color: var(--secondColor);
      }
    }

    & .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
