import React from 'react';
import styled from 'styled-components';

function AdminLineDivider(props) {
  const {title, id = ''} = props;

  return (
    <Wrapper id={id}>
      <div className="line" />
      <div className="text">{title}</div>
      <div className="line" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;

  .text {
    margin: 0 10px;
    font-weight: bold;
    font-size: 1rem;
  }

  .line {
    flex: 1;
    height: 1px;
    background-color: #343434;
  }
`;

export default AdminLineDivider;
